<template>
  <b-card>
    <validation-observer v-slot="{ invalid }" ref="passwordObserver">
      <!-- form -->
      <b-form>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <FormField
              id="account-new-password"
              :label="password.label"
              :module="module"
              :entity="entity"
              :form-field="password"
              autofocus
            />
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <FormField
              id="account-retype-new-password"
              :label="repeatPassword.label"
              :module="module"
              :entity="entity"
              :form-field="repeatPassword"
            />
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="invalid"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="submitForm"
            >
              {{ $t('Save Changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BButton, BForm, BRow, BCol, BCard } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { getUserId } from '@/auth/utils'
import FormField from '@/layouts/entity/FormField.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
    ValidationObserver,
    FormField,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      module: '',
      entity: {},
    }
  },
  computed: {
    password() {
      return {
        id: 'newPassword',
        type: 'password',
        label: this.$t('New Password'),
        required: true,
        rules: 'password',
      }
    },
    repeatPassword() {
      return {
        id: 'retypePassword',
        type: 'password',
        label: this.$t('Retype New Password'),
        required: true,
        rules: 'password',
      }
    },
  },
  methods: {
    submitForm() {
      if (this.entity.newPassword !== this.entity.retypePassword) {
        alert(this.$t("Passwords don't match!"))
        return false
      }
      return axios
        .put('/users/update-password', {
          id: getUserId(),
          // oldPassword: this.entity.newPassword,
          newPassword: this.entity.newPassword,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Entity Saved'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('Something went wrong!'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
