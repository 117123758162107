<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group>
            <b-media
              class="mb-2"
            >
              <template #aside>
                <b-avatar
                  :src="options.participant.profilePhoto"
                  :text="avatarText(options.participant.firstName)"
                  size="120px"
                  rounded
                />
              </template>
              <h4
                class="mb-1"
              >
                {{ options.participant.firstName + ' ' + options.participant.lastName }}
              </h4>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="$refs.inputFile.click()"
                >
                  <input
                    id="user-avatar"
                    ref="inputFile"
                    type="file"
                    accept="image/*"
                    class="d-none"
                    @input="handleImageChange($refs.inputFile)"
                  />
                  <span>{{ options.participant.profilePhoto ? $t('Update') : $t('Add') }}</span>
                </b-button>
                <b-button
                  v-if="options.participant.profilePhoto"
                  variant="outline-secondary"
                  class="ml-1"
                  @click="handleImageChange(null)"
                >
                  <span>{{ $t('Delete') }}</span>
                </b-button>
              </div>
            </b-media>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('First Name')"
            label-for="account-firstName"
          >
            <b-form-input
              v-model="options.participant.firstName"
              :placeholder="$t('First Name')"
              name="firstName"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('Last Name')"
            label-for="account-lastName"
          >
            <b-form-input
              v-model="options.participant.lastName"
              :placeholder="$t('Last Name')"
              name="lastName"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group
            :label="$t('Company')"
            label-for="account-company"
          >
            <v-select
              id="companyList"
              v-model="options.company"
              label="title"
              :options="companyOptions"
            />
          </b-form-group>
        </b-col> -->
        <b-col sm="6">
          <b-form-group
            :label="$t('professional title')"
            label-for="account-professionalTitle"
          >
            <b-form-input
              v-model="options.participant.professionalTitle"
              :placeholder="$t('professional title')"
              name="professionalTitle"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="options.participant.email"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="submitForm"
          >
            {{ $t('Save Changes') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="reloadData"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BAvatar, BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMedia,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: JSON.parse(JSON.stringify(this.userData)),
      // companyOptions: [],
    }
  },
  mounted() {
    this.options = JSON.parse(JSON.stringify(this.userData))

    // this.$store.dispatch('app-common/fetchCompanies')
    //   .then(response => {
    //     this.companyOptions = response.data
    //   })
  },
  methods: {
    reloadData() {
      this.options = JSON.parse(JSON.stringify(this.userData))
    },

    submitForm() {
      this.$store.dispatch('app-common/updateUserData', {
        ...this.options,
      })
        .then(() => {
          this.$emit('update')

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Entity Saved'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('Something went wrong!'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    handleImageChange(input) {
      const file = input && input.files && input.files[0]
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        this.options.participant.profilePhoto = reader.result
      }, false)

      if (file) {
        reader.readAsDataURL(file)
      } else {
        this.options.participant.profilePhoto = ''
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      avatarText,
    }
  },
}
</script>
