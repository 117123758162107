<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <account-setting-general
        ref="general"
        :user-data="userData"
        @update="reloadData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Information') }}</span>
      </template>

      <account-setting-information
        ref="info"
        :user-data="userData"
        @update="reloadData"
      />
    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('change password') }}</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- pricing tab -->
    <b-tab>
      <!-- title -->
      <template v-if="isAdmin" #title>
        <feather-icon
          icon="SlidersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Subscriptions') }}</span>
      </template>

      <Subscriptions />
    </b-tab>
    <!--/ pricing tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import {
  isAdmin,
  isSeller,
  isSupplier,
} from '@/auth/utils'
import Subscriptions from '@/views/subscriptions/Subscriptions.vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    Subscriptions,
  },
  computed: {
    userData() {
      return this.$store.getters['app-common/userData']
    },
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
  },
  methods: {
    reloadData() {
      this.$refs.general.reloadData()
      this.$refs.info.reloadData()
    },
  },
}
</script>
