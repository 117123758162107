<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group
            :label="$t('bio')"
            label-for="bio-area"
          >
            <b-form-textarea
              id="bio-area"
              v-model="options.participant.bio"
              rows="4"
            />
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <!-- Country -->
        <b-col md="6">
          <b-form-group
            :label="$t('Country')"
            label-for="countryList"
          >
            <v-select
              id="countryList"
              v-model="options.participant.country"
              :options="countryOptions"
              label="title"
            />
          </b-form-group>
        </b-col>
        <!--/ Country -->

        <b-col sm="6">
          <b-form-group
            :label="$t('address')"
            label-for="account-address"
          >
            <b-form-input
              v-model="options.participant.address"
              :placeholder="$t('address')"
              name="address"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            :label="$t('city')"
            label-for="account-city"
          >
            <b-form-input
              v-model="options.participant.city"
              :placeholder="$t('city')"
              name="city"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            :label="$t('postal code')"
            label-for="account-postalCode"
          >
            <b-form-input
              v-model="options.participant.postalCode"
              :placeholder="$t('postal code')"
              name="postalCode"
            />
          </b-form-group>
        </b-col>

        <!-- phone -->
        <b-col md="6">
          <b-form-group
            :label="$t('telephone')"
            label-for="phone"
          >
            <cleave
              id="phone"
              v-model="options.participant.telephone"
              :raw="false"
              :options="clevePhone"
              :placeholder="$t('telephone')"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <!-- phone -->

        <!-- birth date -->
        <b-col md="6">
          <b-form-group
            :label="$t('birth date')"
            label-for="example-datepicker"
          >
            <flat-pickr
              v-model="options.participant.birthDate"
              :placeholder="$t('birth date')"
              class="form-control"
              name="date"
            />
          </b-form-group>
        </b-col>
        <!--/ birth date -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="submitForm"
          >
            {{ $t('Save Changes') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="reloadData"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    Cleave,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: JSON.parse(JSON.stringify(this.userData)),
      countryOptions: [],
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  mounted() {
    this.options = JSON.parse(JSON.stringify(this.userData))

    this.$store.dispatch('app-common/fetchCountries')
      .then(response => {
        this.countryOptions = response.data
      })
  },
  methods: {
    reloadData() {
      this.options = JSON.parse(JSON.stringify(this.userData))
    },

    submitForm() {
      this.$store.dispatch('app-common/updateUserData', {
        ...this.options,
      })
        .then(() => {
          this.$emit('update')

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Entity Saved'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('Something went wrong!'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
